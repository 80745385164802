<template>
  <div>
    <el-form>
      <el-row :gutter="24" v-if="isAdd">
        <el-col :md="16">
          <el-form-item class="flex" label="使用角色">
            <el-button type="primary" @click="getDialog('role')">添加</el-button>
            <el-button type="danger" @click="delTable('visitRoleReqVos')">删除</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <vxe-table class="table" :align="allAlign"  v-if="isAdd"
      @checkbox-all="({records}) => selectAllEvent(records, 'role')"
                @checkbox-change="({records}) => selectChangeEvent('role',records)"
                :data="value.visitRoleReqVos">
        <vxe-table-column type="checkbox"
                          width="50"></vxe-table-column>
        <vxe-table-column field="roleCode" title="角色编码"></vxe-table-column>
        <vxe-table-column field="roleName" title="角色名称"></vxe-table-column>
      </vxe-table>
      <el-row :gutter="24" class="mt30">
        <el-col :md="16">
          <el-form-item class="flex" label="拜访步骤">
            <el-button type="primary" @click="getDialog('step')" v-if="!disabled">添加</el-button>
            <el-button type="danger" @click="delTable('visitRoleDirectoryReqVos')" v-if="!disabled">删除</el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <vxe-table :align="allAlign" ref="xTable"
      @checkbox-all="({records}) => selectAllEvent(records, 'step')"
                @checkbox-change="({records}) => selectChangeEvent('step',records)"
                :edit-config="{trigger: 'click', mode: 'cell', activeMethod: activeCellMethod}"
                :data="value.visitRoleDirectoryReqVos">
        <vxe-table-column type="checkbox"
                          width="50"
                          v-if="!disabled"></vxe-table-column>
        <vxe-table-column field="directoryName" title="步骤名称"></vxe-table-column>
        <vxe-table-column field="sort" title="拜访顺序" :edit-render="{name: '$input', events: {change: sexChangeEvent}}"></vxe-table-column>
        <vxe-table-column field="doNot" title="是否必做" :edit-render="{name: '$select', options: options, events: {change: sexChangeEvent}}"></vxe-table-column>
      </vxe-table>
    </el-form>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect"/>
    <!-- 步骤弹窗 -->
    <Modal :modalConfig="modalConfigStep" @onClose="closeModal">
      <el-row type="flex" justify="start" :gutter="20">
        <el-col :md="10">
          <el-form-item label="模版编码">
            <el-select
              v-model="params.pageCode"
              clearable
              filterable
              placeholder="请选择模版编码模糊搜索"
            >
              <el-option
                v-for="item in pageCodeList"
                :key="item.dictCode"
                :label="item.dictValue"
                :value="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="10">
          <el-form-item label="步骤名称">
            <el-input v-model="params.fromName" clearable placeholder="请输入步骤名称进行模糊搜索"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <el-button type="primary" icon="el-icon-search" @click="getStepList">查询</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <vxe-table ref="dTable"
            :align="allAlign"
            @checkbox-all="({records}) => selectAllEvent(records,'stepDialog')"
            row-id="directoryCode"
            :checkbox-config="{
              checkRowKeys: value.defaultSelecteRows,
              reserve: true
              }"
            @checkbox-change="({records,row,checked}) => selectChangeEvent('stepDialog',records,row,checked)"
            :data="visitRoleDirectoryList">
          <vxe-table-column type="checkbox"
                            width="50"></vxe-table-column>
          <vxe-table-column field="directoryName" title="步骤名称"></vxe-table-column>
        </vxe-table>
        </el-col>
      </el-row>
      <div class="page-pageination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.pageNum"
          :page-sizes="[5, 10, 15, 20, 50, 100, 150, 200]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalResult"
        >
        </el-pagination>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="submit" size="small" icon="el-icon-check" >提交</el-button>
        <el-button type="danger"  @click="closeModal" size="small" icon="el-icon-close" >关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
// import request from '@/src/utils/request';
import Modal from '../../../../../../../components/modal';
import request from '../../../../../../../utils/request';
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  components: {
    Modal,
    SelectConfig,
  },
  props: {
    value: Object,
    isAdd: Boolean,
    disabled: Boolean,
    visitType: String,
    customerType: String, // 客户类型
    pageCode: String, // 模板类型
    fromName: String, // 步骤名称
    clientSubclass: String, // 客户细类
  },
  data() {
    return {
      visitRoleReqVos: [], // 使用角色
      visitRoleDirectoryReqVos: [], // 拜访步骤
      visitRoleDirectoryList: [],
      modalConfig: {
        title: '角色',
        visible: false,
        type: 'Modal',
      },
      modalConfigStep: {
        title: '步骤',
        visible: false,
        type: 'Modal',
      },
      selectionList: [],
      defaultSelecteRows: [], // 默认选中的角色
      selectionStepList: [], // 已选择的步骤list，重新选择客户后要清空 #61159
      hasclientSubclass: true, // 是否有客户细类
      checkList: [],
      allAlign: null,
      options: [{
        label: '是',
        value: 'yes',
      }, {
        label: '否',
        value: 'no',
      }],
      // 筛选条件
      params: {
        visitType: null, // 拜访类型
        customerType: null, // 客户类型
        clientSubclass: '', // 客户细类
        pageCode: '', // 模板
        fromName: '', // 步骤名称
        pageNum: 1,
        pageSize: 10,
      },
      totalResult: 0,
      pageCodeList: [], // 模板编码list
    };
  },
  watch: {
    visitType: {
      immediate: true,
      handler(val) {
        this.selectionStepList = [];
        this.params.visitType = val;
      },
    },
    customerType: {
      immediate: true,
      handler(val) {
        this.selectionStepList = [];
        this.params.customerType = val;
      },
    },
    clientSubclass: {
      immediate: true,
      handler(val) {
        this.params.clientSubclass = val;
      },
    },
    value(val) {
      if (val) {
        this.selectionStepList = val.visitRoleDirectoryReqVos || [];
      }
    },
  },
  created() {
    this.getPageCodeList();
    // 查询全局参数 获取是否精确到细分客户
    const params = {
      parameterCode: 'sfa_visit_step_config_client_subclass_range',
    };
    const requestUrl = '/mdm/mdmparametermanage/query';
    request.post(requestUrl, params).then((res) => {
      if (res.success) {
        this.hasclientSubclass = res.result.parameterValue === 'Y';
        // console.log(res.result.parameterValue);
      }
    });
  },
  methods: {
    // 获取模版编码列表
    getPageCodeList() {
      const requestUrl = '/mdm/mdmdictdata/list';
      request.post(requestUrl, { dictTypeCode: 'sfa_visit_step' })
        .then((res) => {
          this.pageCodeList = res.result;
        });
    },
    activeCellMethod() {
      if (this.disabled) {
        return false;
      }
      return true;
    },
    // 获取步骤列表
    getStepList() {
      const { params } = this;
      request.post('/sfa/sfaVisitRoleConfigController/getStepFromList', params).then((res) => {
        if (res.result.data) {
          this.totalResult = res.result.count || 0;
          const result = res.result.data || [];
          this.visitRoleDirectoryList = [];
          result.forEach((v) => {
            const item = v;
            this.visitRoleDirectoryList.push({
              directoryName: item.fromName,
              directoryCode: item.id,
            });
          });
          if (Array.isArray(this.selectionStepList)) {
            this.$refs.dTable.setCheckboxRow(this.selectionStepList, true);
          }
        }
      });
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getStepList();
    },
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.getStepList();
    },
    sexChangeEvent(row) {
      this.$refs.xTable.clearActived();// 清除单元格激活状态
      this.$refs.xTable.setActiveCell(row.row, row.column.property);// 设置单元格为激活状态
    },
    getDialog(type) {
      if (type === 'role') {
        const params = {
          functionCode: 'sfaCenter_visitManage_visitStep_add_list',
          hasChild: 'name',
          data: this.visitRoleReqVos,
          idKey: 'id',
        };
        this.$refs.selectConfig.openSelectModal(params);
      } else { // 拜访步骤
        if (!this.params.visitType) {
          this.$message.warning('请先选择拜访类型！');
          return false;
        }
        if (!this.params.customerType && this.params.visitType !== 'UNFAMILIAR_VISIT') { // 陌拜时不需要传客户类型
          this.$message.warning('请先选择客户类型！');
          return false;
        }
        // UNFAMILIAR_VISIT 陌拜不需要选择细分客户 没有精确到细分客户时也不需要选择细分客户
        // console.log(this.hasclientSubclass);
        if (!this.params.clientSubclass && this.params.visitType !== 'UNFAMILIAR_VISIT' && this.hasclientSubclass) {
          if (this.params.customerType === 'dealer') { // 经销商
            this.$message.warning('请先选择经销商类型！');
          } else {
            this.$message.warning('请先选择终端类型！'); // 终端
          }
          return false;
        }
        // if(!this.params.)
        this.visitRoleDirectoryList = [];
        this.modalConfigStep.visible = true;
        this.getStepList();
      }
    },
    onGetSelect(val) {
      this.$set(this.value, 'visitRoleReqVos', val);
      this.$emit('input', this.value);
    },
    delTable(type) {
      if (this[type].length) {
        this[type].map((v) => {
          const item = v;
          let index = null;
          if (type === 'visitRoleDirectoryReqVos') { // 拜访步骤
            index = this.value[type].findIndex((val) => val.directoryCode === item.directoryCode);
            if (index > -1) {
              this.value.defaultSelecteRows.splice(index, 1);
              this.value.visitRoleDirectoryReqVos.splice(index, 1);
              this.value.visitRoleDirectoryReqVos.map((a, b) => {
                const n = a;
                n.sort = b + 1;
                return n;
              });
            }
          } else {
            index = this.value[type].findIndex((val) => val.id === item.id);
            if (index > -1) {
              this.value[type].splice(index, 1);
            }
          }
          return item;
        });
        this[type] = [];
        this.$forceUpdate();
        this.$emit('input', this.value);
      } else {
        this.$message.error('请选择数据');
      }
    },
    selectChangeEvent(type, records, row, checked) {
      if (type === 'stepDialog') {
        if (checked) {
          if (row.directoryCode === 'inStore') {
            this.selectionStepList.unshift(row);
          } else {
            this.selectionStepList.push(row);
          }
        } else {
          const index = this.selectionStepList.findIndex((val) => val.directoryCode === row.directoryCode);
          this.selectionStepList.splice(index, 1);
        }
      } else if (type === 'role') {
        this.visitRoleReqVos = records;
      } else {
        this.visitRoleDirectoryReqVos = records;
      }
    },
    selectAllEvent(records, type) {
      if (type === 'stepDialog') {
        this.selectionStepList = records;
      } else if (type === 'role') {
        this.visitRoleReqVos = records;
      } else {
        this.visitRoleDirectoryReqVos = records;
      }
    },
    submit() {
      this.$set(this.value, 'visitRoleDirectoryReqVos', []);
      this.$set(this.value, 'defaultSelecteRows', []);
      if (this.selectionStepList.length) {
        // 排序
        const index = this.selectionStepList.findIndex((val) => val.directoryCode === 'outStore');
        if (index > -1) {
          const obj = this.selectionStepList.splice(index, 1);
          this.selectionStepList.push(obj[0]);
        }
        this.selectionStepList.map((v, k) => {
          const item = v;
          item.sort = k + 1;
          item.doNot = item.doNot || 'yes';
          this.value.defaultSelecteRows.push(item.directoryCode);
          return item;
        });
        this.$set(this.value, 'visitRoleDirectoryReqVos', this.selectionStepList);
        this.$emit('input', this.value);
        this.modalConfigStep.visible = false;
        this.selectionStepList = this.value.visitRoleDirectoryReqVos;
      } else {
        this.$message.error('请选择数据');
      }
    },
    closeModal() {
      this.modalConfigStep.visible = false;
      this.modalConfig.visible = false;
    },
  },
};
</script>
<style lang="less" scoped>
  .flex{
    display: flex;
  }
  /deep/ .el-form-item__label{
    width: 125px;
  }
  /deep/ .el-form-item__content{
    .flex;
    align-items: center;
  }
  .dialog-footer {
    position: absolute;
    bottom: 0;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 99;
    background-color: white;
  }
  /deep/ .page-pageination {
    text-align: right;
    padding-top: 10px;
    .el-input {
      display: inline-block !important;
    }
  }
  .table{
    margin-bottom: 20px;
  }
</style>
