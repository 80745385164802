<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import formTransfor from './components';

formCreate.component('formTransfor', formTransfor);

export default {
  extends: Form,
  components: {
    // Modal,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
      clientSubclassName: String, // 客户细类Name
      hasclientSubclass: true, // 是否有客户细类
    };
  },
  async created() {
    // 查询全局参数 获取是否精确到细分客户
    const params = {
      parameterCode: 'sfa_visit_step_config_client_subclass_range',
    };
    const requestUrl = '/mdm/mdmparametermanage/query';
    request.post(requestUrl, params).then((res) => {
      if (res.success) {
        this.hasclientSubclass = res.result.parameterValue === 'Y';
      }
    });
    const _this = this;
    // 拜访步骤新增
    const rule = await this.getBeforFormRule('sfaCenter_visitManage_visitStep_add');
    const newRule = [];
    rule.forEach((item) => {
      const v = item;
      if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
        v.props = {
          ...v.prop,
          disabled: true,
        };
        if (v.type === 'hidden') {
          v.type = 'input';
        }
      }
      if (v.field === 'visitType') { // 拜访类型
        v.on = {
          ...v.on,
          change: (val) => {
            if (val === 'UNFAMILIAR_VISIT') { // 如果拜访类型是陌拜则隐藏客户类型 和客户细类
              this.hiddenFields(true, ['sfacusType', 'clientSubclassTerminal', 'clientSubclassDealer']);
            } else {
              this.hiddenFields(false, 'sfacusType');
            }
            const oneRule = this.getRule('visitRoleReqVos');
            oneRule.props = {
              ...oneRule.props,
              visitType: val,
            };
            oneRule.value = {
              ...oneRule.value,
              visitRoleDirectoryReqVos: [],
              defaultSelecteRows: null,
            };
          },
        };
      } else if (v.field === 'sfacusType') { // 客户类型
        v.on = {
          ...v.on,
          change: (val) => {
            const oneRule = this.getRule('visitRoleReqVos');
            oneRule.props = {
              ...oneRule.props,
              customerType: val,
            };
            oneRule.value = {
              ...oneRule.value,
              visitRoleDirectoryReqVos: [],
              defaultSelecteRows: null,
            };
            // 根据客户类型选择细分客户 精确到细分客户的情况下
            if (val === 'dealer' && this.hasclientSubclass) { // 经销商
              _this.hiddenFields(true, ['clientSubclassTerminal']);
              _this.hiddenFields(false, ['clientSubclassDealer']);
            } else if (val === 'terminal' && this.hasclientSubclass) { // 终端
              _this.hiddenFields(true, ['clientSubclassDealer']);
              _this.hiddenFields(false, ['clientSubclassTerminal']);
            }
            _this.setValue({
              clientSubclassDealer: null,
              clientSubclassTerminal: null,
            });
          },
        };
      } else if (v.field === 'minVisitTime') {
        v.on = {
          ...v.on,
          change: (val) => {
            console.log('进入了改变', val);
            this.setValue({
              minVisitTime: val || 0,
            });
          },
        };
      }
      if (v.field === 'clientSubclassDealer') { // 经销商 要传给 clientSubclass
        v.on = {
          ...v.on,
          change: (val) => {
            if (val) {
              this.clientSubclassName = v.options.find((i) => i.dictCode === val).dictValue;
            } else {
              this.clientSubclassName = null;
            }
            // 使用角色
            const oneRule = this.getRule('visitRoleReqVos');
            oneRule.props = {
              ...oneRule.props,
              clientSubclass: val,
            };
            oneRule.value = {
              ...oneRule.value,
              visitRoleDirectoryReqVos: [],
              defaultSelecteRows: null,
            };
          },
        };
      }
      if (v.field === 'clientSubclassTerminal') { // 终端 要传给 clientSubclass
        v.on = {
          ...v.on,
          change: (val) => {
            if (val) {
              this.clientSubclassName = v.options.find((i) => i.dictCode === val).dictValue;
            } else {
              this.clientSubclassName = null;
            }
            const oneRule = this.getRule('visitRoleReqVos');
            oneRule.props = {
              ...oneRule.props,
              clientSubclass: val,
            };
            oneRule.value = {
              ...oneRule.value,
              visitRoleDirectoryReqVos: [],
              defaultSelecteRows: null,
            };
          },
        };
      }
      if (v.field === 'isOffLine') {
        v.value = 'N';
      }
      if (v.field === 'visitRoleReqVos') {
        if (this.formConfig.type === 'edit') {
          newRule.push({
            type: 'formTransfor',
            field: 'visitRoleReqVos',
            title: '',
            value: {},
            props: {
              isAdd: false,
            },
          });
        } else if (this.formConfig.type === 'view') {
          newRule.push({
            type: 'formTransfor',
            field: 'visitRoleReqVos',
            title: '',
            value: {},
            props: {
              isAdd: false,
              disabled: true,
            },
          });
          this.buttons.submit = false;
        } else {
          newRule.push({
            type: 'formTransfor',
            field: 'visitRoleReqVos',
            title: '',
            value: {},
            props: {
              isAdd: true,
            },
          });
        }
      } else {
        newRule.push(v);
      }
    });
    this.rule = newRule;
    this.reload(this.rule);

    // 默认隐藏2个字段
    _this.hiddenFields(true, ['clientSubclassTerminal', 'clientSubclassDealer']);
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
      request
        .get('/sfa/sfaVisitRoleConfigController/queryDetailById', {
          id: this.formConfig.id,
        })
        .then((res) => {
          if (res.success) {
            const { result } = res;
            // 有客户细类才赋值
            if (this.hasclientSubclass) {
              // 为客户细类赋值
              if (result.sfacusType === 'terminal') {
                this.hiddenFields(false, ['clientSubclassTerminal']);
                this.hiddenFields(true, ['clientSubclassDealer']);
                this.setValue({ clientSubclassTerminal: result.clientSubclass });
              } else {
                this.hiddenFields(true, ['clientSubclassTerminal']);
                this.hiddenFields(false, ['clientSubclassDealer']);
                this.setValue({ clientSubclassDealer: result.clientSubclass });
              }
            }
            //  如果是陌拜则不需要客户类型和客户细类
            if (result.visitType === 'UNFAMILIAR_VISIT') {
              this.hiddenFields(true, ['sfacusType', 'clientSubclassTerminal', 'clientSubclassDealer']);
            }
            const defaultSelecteRows = [];
            result.visitRoleDirectoryRespVos.map((v) => {
              const item = v;
              defaultSelecteRows.push(item.directoryCode);
              return item;
            });
            const visitRoleReqVos = {
              visitRoleReqVos: {
                visitRoleDirectoryReqVos: result.visitRoleDirectoryRespVos,
                defaultSelecteRows,
              },
              ...result,
            };
            this.setValue(visitRoleReqVos);
            const steepRule = this.getRule('visitRoleReqVos');
            steepRule.props = {
              ...steepRule.props,
              visitType: result.visitType,
              customerType: result.sfacusType,
              clientSubclass: result.clientSubclass,
            };
            this.row = res.result;
          }
        });
    }
  },
  methods: {
    // 提交
    submit() {
      const formData = this.getFormData();
      const param = {
        sfacusType: formData.sfacusType,
        visitType: formData.visitType,
        isOffLine: formData.isOffLine,
        visitRoleDirectoryReqVos: formData.visitRoleReqVos.visitRoleDirectoryReqVos,
        visitRoleReqVos: formData.visitRoleReqVos.visitRoleReqVos,
        // clientSubclass: formData.
        // 根据客户类型传相应的客户细类
        clientSubclass: formData.sfacusType === 'dealer'
          ? formData.clientSubclassDealer // 经销商
          : formData.clientSubclassTerminal, // 终端
        clientSubclassName: this.clientSubclassName,
        minVisitTime: formData.minVisitTime,
      };
      const { visitRoleDirectoryReqVos } = param;
      const sortList = [];
      if (visitRoleDirectoryReqVos) {
        visitRoleDirectoryReqVos.forEach((v) => {
          const item = v;
          sortList.push(Number(item.sort));
        });
      } else {
        this.$message.error('请选择拜访步骤');
      }

      if (this.isRepeat(sortList)) {
        this.$message.error('拜访顺序不能重复');
        return false;
      }
      if (param) {
        let url = '/sfa/sfaVisitRoleConfigController/save';
        let params = { ...param };

        if (this.formConfig.type === 'edit') {
          url = '/sfa/sfaVisitRoleConfigController/update';
          params = Object.assign(this.row, param);
        }
        //  const oneRule = this.getRule('minVisitTime');

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });

            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
    isRepeat(arr) {
      const hash = {};
      for (const i of arr) {
        if (hash[i]) {
          return true;
        }
        hash[i] = true;
      }
      return false;
    },
  },
};
</script>
